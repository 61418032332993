.home-hero {
  padding-block: 70px;
}

.home-hero h1 {
  font-family: PlusJakartaSans;
  font-size: 55px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;
}

.home-hero h1 span {
  color: #ff7a00;
}

.home-hero p {
  font-family: PlusJakartaSans;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-block: 60px 30px;
  padding-left: 24px;
  border-left: 3px solid #ff7a00;
}

.home-hero .hero_button {
  border: 1px solid #ff7a00;
  border-radius: 4px;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  font-family: PlusJakartaSans;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  background: #ff7a00;
  color: #fff;
  outline: none;
}

.home-hero .buy_now {
  display: none;
}

.home-hero .sign-up_btn img {
  /* transform: rotate(90deg); */
  position: relative;
  /* z-index: -1; */
}

.home-infos {
  background: #1b1a19;
}

.home-section {
  padding-block: 60px;
  scroll-margin-block: 105px;
}

.home-title {
  font-family: PlusJakartaSans;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
}

.home-title.white,
.home-p.white {
  color: #e6e6e6;
}

.home-title.center,
.home-p.center {
  text-align: center;
}

.home-p {
  font-family: PlusJakartaSans;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 24px;
}

.info-items {
  margin-top: 45px;
}

.info-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  max-width: 600px;
  margin-bottom: 24px;
}

.info-item .icon {
  width: 100%;
  max-width: 46px;
  height: 46px;
  background: #ff7a00;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.info-item h3 {
  font-family: PlusJakartaSans;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #e6e6e6;
}

.info-item p {
  font-family: PlusJakartaSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #e6e6e6 !important;
  margin: 0;
}

.info-item p span {
  font-weight: 600;
  color: #e6e6e6 !important;
}

.grid_container {
  display: flex;
  flex-flow: column;
  margin-top: 24px;
}

.grid_item {
  padding: 20px 16px;
  cursor: pointer;
}

.how-to-buy_item {
  border-bottom: 1px solid #eaecf0;
}

.grid_item.active {
  border-bottom: 1px solid #ff7a00;
}

.grid_item .title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.grid_item .title_container h3 {
  font-family: PlusJakartaSans;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.grid_item p {
  font-family: PlusJakartaSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #667085;
  margin: 0;
  margin-top: 16px;
  padding-right: 30px;
}

.home-faq {
  background: #f1f4f6;
}

.faq_item {
  border-bottom: 1px solid #ffffff;
}

.home-contact .button {
  max-width: 160px;
  margin: 24px auto 0;
  background: #ff7a00;
  color: #fff;
  border-radius: 4px;
  color: #fff;
  padding: 8px 0px;
  position: relative;
  width: 160px;
  font-family: PlusJakartaSans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.home-footer {
  background: #1e1e1e;
  padding-bottom: 0;
}

.home-footer_upper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home-footer_info {
  max-width: 320px;
}

.home-footer_info h3 {
  color: #ff7a00;
  font-size: 32px;
  font-weight: 600;
}

.home-footer_info-p {
  font-family: PlusJakartaSans;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #9ba4b1;
  margin: 0;
  margin-top: 14px;
}

.home-footer_upper .right-side {
  text-align: right;
}

.home-footer_under {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  border-top: 1px solid #4b4b4b;
  margin-top: 40px;
}

.home-footer_under p {
  font-family: PlusJakartaSans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  margin: 0;
}

.home-footer_under div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.home-footer_under a {
  font-family: PlusJakartaSans;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  color: #9ba4b1;
  text-decoration: underline;
}

/* Media responsive */
.hero_btn {
  display: none;
}
/* home page responsive start*/
@media only screen and (max-width: 768px) {
  .hero_btn {
    display: block;
    background-color: #fff;
  }
  .home-hero {
    padding-block: 32px;
  }

  .home-hero h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .home-hero p {
    font-size: 16px;
    line-height: 20px;
    padding-left: 14px;
    margin-top: 40px;
  }

  .home-hero .sign-up_btn {
    display: none;
  }

  .home-hero .buy_now {
    width: 100%;
    display: flex;
  }

  .home-section {
    padding-block: 32px;
    scroll-margin-block: 60px;
  }

  .home-title {
    font-size: 24px;
    line-height: 30px;
  }

  .home-p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
  }

  .info-items {
    margin-top: 32px;
  }

  .info-item {
    margin-bottom: 16px;
  }

  .info-item p {
    font-size: 14px;
    line-height: 16px;
  }

  .grid_item {
    padding: 14px 0;
  }

  .grid_item .title_container h3 {
    font-size: 16px;
    line-height: 20px;
  }

  .grid_item p {
    font-size: 14px;
    line-height: 18px;
    padding-right: 0;
    margin-top: 12px;
  }

  .home-contact .button {
    max-width: 100%;
    border-radius: 8px;
    width: 100%;
  }

  .contact_us_btn {
    width: 100%;
  }

  .home-footer_info {
    max-width: 100%;
  }

  .home-footer_upper {
    flex-direction: column;
  }

  .home-footer_upper .right-side {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 16px;
    margin-top: 16px;
  }

  .home-footer_under {
    flex-wrap: wrap;
    gap: 10px;
    padding: 16px 0;
    margin-top: 16px;
  }
}
