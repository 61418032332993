.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 24px;
  /* identical to box height, or 30% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0px;

  /* Primary Colour */

  color: #ff7a00;
}

p,
span,
button {
  color: #1e1e1e;
}

.App-link {
  color: #61dafb;
}

.verification-container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding-top: 2rem;
}

.verification-container button {
  margin: 0 auto;
  margin-bottom: 35px;
}

.otp-input {
  margin: 0 6px;
  width: 43px;
  text-align: center;
  height: 48px;
  font-size: 1.5rem;
  line-height: 24px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  border-width: 0px 0px 2px;
  background: transparent;
  border-radius: 0px;
  outline: none;
  color: rgb(0, 0, 0);
}

.otp-container {
  display: flex;
}

.otp-container input[value=""] {
  background-image: radial-gradient(#ffc38c 20%, transparent 25%) !important;
  background-size: 0.625em 0.625em !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-bottom: 2px solid #ffc38c !important;
}

.otp-input-container {
  width: 100%;
  margin-top: 30px;
}
