.containeer {
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.formDiv {
  width: 327px;
  padding: 30px;
  border-radius: 10px;
  -webkit-box-shadow: -0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -0px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

.creditCard {
  margin-bottom: 10%;
}
.form-control:focus {
  outline: none;
}
.cardInput {
  margin-bottom: 10px;
  background-color: #f2f2f2;
  box-shadow: 0px 5px 15px 0px rgba(209, 209, 209, 0.03);
  border: none;
  outline: none;
  border-radius: 10px;
  height: 48px;
  width: 100%;
  padding: 0 15px;
  font-size: 14px;
}
.addCard_content {
  width: 469px;
  max-width: 90vw;
  margin: 0 auto;
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 50px 45px 40px 45px;
}
.inputTick {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url(../../assets/images/icons/tick.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.card_wrapper {
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}
@media only screen and (max-width: 420px) {
  .rccs__card {
    margin: 0 !important;
    padding-left: 5px !important;
  }
  .addCard_content {
    width: 100% !important;
    padding: 10px !important;
  }
  .securityLabel {
    margin-bottom: 25px;
  }
  .securityLabel {
    margin-bottom: 0px !important;
  }
}
@media only screen and (max-width: 768px) {
  .addCard_content {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 350px) {
  .rccs__card {
    margin: 0 !important;
    padding-left: 5px !important;
    width: 100% !important;
  }
  .rccs {
    width: 100% !important;
  }
}
@media only screen and (max-width: 323px) {
  .securityLabel {
    margin-bottom: 24px !important;
  }
}
