@font-face {
  font-family: "PlusJakartaSans";
  src: url("../assets/fonts/PlusJakartaSans-Regular.ttf");
}
@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: url("../assets/fonts/PlusJakartaSans-Medium.ttf");
}
.line {
  background-color: red !important;
}
* {
  scrollbar-width: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.phone1 {
  margin-bottom: 5px;
}
body {
  font-family: "PlusJakartaSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f-300 {
  font-weight: 300 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.f-400 {
  font-weight: 400 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.f-900 {
  font-weight: 900 !important;
}

a,
a:hover {
  text-decoration: none;
}

.container {
  max-width: 89.583vw;
  margin: 0 auto;
}

.h2 {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

.h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
}

.button {
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
  /* max-width: 260px; */
  width: 100%;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 0px;
  border: none;
}

.button.orange {
  color: #fff;
  background: #ff7a00;
}

.button.transparent {
  background: white;
  color: #ff7a00;
  border: 1px solid #ff7a00;
}

.button.disabled {
  background: #ffc38c;
  pointer-events: none;
}
.button.btnDisabled {
  background: #ffc38c;
}
.buttonn1 {
  font-size: 1rem;
  background: #ff7a00;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 259px;
  border-radius: 27px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #fff;
  border: none;
  justify-content: center;
}

.edit-contact-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 30px 35px;
}

.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
}

.empty-data-text {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 30px;
}

/* HOME PAGE STYLE */

.home-page {
  display: flex;
}

.home-left {
  flex: 1;
}

.home-left .header {
  padding: 24px 0;
  border-bottom: 1px solid #f4eae5;
  position: sticky;
  top: 0;
  background-color: #fff;
}

.home-left .header .header__container {
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
}

.home-left .home-container {
  padding-inline: 60px !important;
}

.home-left .header .header_links_container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-left .header .header_links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  gap: 24px;
}

.home-left .header .header_links li a {
  font-family: PlusJakartaSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.home-left .header .logo {
  font-size: 40px;
}

.home-left .header .logo button {
  font-weight: 500;
}

.home-left .header .buy-sell {
  display: none;
}

.home-left .header .login-link {
  background: #ff7a00;
  border-radius: 4px;
  transform: none;
  color: #fff;
  padding: 8px 50px;
  position: relative;
  width: 160px;
  font-family: PlusJakartaSans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.home-left .burger-icon {
  display: none;
}

.home-right {
  width: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
  padding-inline: 50px;
  position: sticky;
  top: 0;
}

.home-right > div {
  width: 100%;
}

.home-right .back-arrow {
  display: none;
}

.home-right .h2 {
  font-family: PlusJakartaSans;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
}

.home-right .sub-title {
  font-family: PlusJakartaSans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.home-right .converter__wrapper {
  background-color: #fff;
  margin-top: 35px;
}

.header {
  padding: 30px 0;
}

.header .header__container {
  width: 1290px;
  max-width: 89.583vw;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.header .trade-container {
  max-width: 320px;
  margin: 50px auto 0 auto;
}

.trade-request-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.trade-request-value {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  max-width: 200px;
  word-break: break-all;
}

.logo {
  color: #ff7a00;
  font-size: 80px;
  font-weight: 600;
}

.logo a {
  text-decoration: none;
  color: #ff7a00;
}
::-webkit-scrollbar {
  display: none;
}
.login-link {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  color: #ff7a00;
  border: none;
  background-color: white;
  font-family: "PlusJakartaSans";
  line-height: 20px;
  letter-spacing: 0px;
}

.sub-header {
  text-align: center;
}

.sub-header .container {
  width: 561px;
}

.sub-header .sub-title {
  margin-top: 16px;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #1e1e1e;
}

.converter__wrapper {
  width: 100%;
  max-width: 592px;
  margin: 0 auto;
  margin-top: 50px;
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 64px 71px 40px 71px;
}

.converter__wrapper .button {
  margin: 0 auto;
}
.crypto-icon {
  margin-right: 10px;
}

.crypto-value {
  margin-left: 26px;
  width: 138px;
}

.dropdown.currencies {
  max-width: 155px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  max-height: 240px;
  background: #f2f2f2;
  box-shadow: 0px -25px 32px rgba(237, 217, 198, 0.07);
  filter: drop-shadow(0px -4px 16px rgba(0, 0, 0, 0.06));
  border-radius: 10px;
  padding: 0;
  margin-top: 2px;
  margin-left: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s;
}

.dropdown-list {
  overflow: auto;
}

.dropdown.active {
  height: auto;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.dropdown-item {
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-item__name {
  margin-left: 10px;
}

.dropdown-item:hover {
  background: #fff;
}

.selected-currency {
  margin: 0 5px;
  display: inline-block;
  min-width: 55px;
  text-align: center;
}

.selected-currency-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  width: 138px;
  margin-left: 26px;
}

.dropdown :first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dropdown :last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.converter__input-wrapper {
  position: relative;
  background: #f2f2f2;
  -webkit-box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  border-radius: 4px;
  padding: 12px 16px;
  /* padding-right: 0; */
  height: 48px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  cursor: pointer;
}

.options-dropdown {
  background: #f2f2f2;
  box-shadow: 0px -25px 32px rgba(237, 217, 198, 0.07);
  filter: drop-shadow(0px -4px 16px rgba(0, 0, 0, 0.06));
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  width: 100%;
  z-index: 10;
  margin-top: 2px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.options-dropdown span {
  padding: 12px;
  width: 100%;
  font-size: 14px;
}

.custom-text-input {
  background: #f2f2f2;
  box-shadow: 0px 5px 15px rgb(209 209 209 / 3%);
  border-radius: 10px;
  padding: 12px 16px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  border: none;
  width: 100%;
  outline: none;
  font-weight: 500;
}

.options-dropdown span:hover {
  background: #ffffff;
}

.options-dropdown :first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.options-dropdown :last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom-input-title {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.converter__input {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  max-width: 100%;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "PlusJakartaSans", sans-serif;
  border-right: 1px solid #fff;
  color: #000000;
  width: calc(100% - 120px);
  font-weight: 600;
  overflow: auto;
}

.converter__input::-webkit-input-placeholder {
  color: #9c9c9c;
}

.converter__input::-moz-placeholder {
  color: #9c9c9c;
}

.converter__input:-ms-input-placeholder {
  color: #9c9c9c;
}

.converter__input::-ms-input-placeholder {
  color: #9c9c9c;
}

.converter__input::placeholder {
  color: #9c9c9c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

.input-max-value,
.approx {
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 400;
}

.approx {
  display: flex;
}

.input-max-value {
  width: max-content;
  cursor: pointer;
  margin-left: 15px;
}

.content-under-input {
  display: flex;
  margin-top: 5px;
}

.select {
  font-size: 14px;
  /* margin-right: 1rem; */
  border: none;
  background: none;
  padding-right: 0.5rem;
}

.select:focus {
  outline: none;
}

.select-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.select2-search {
  display: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
}

/* .converter__wrapper select {
  display: none;
} */
.select2-results__options {
  list-style: none;
  padding-left: 0;
}

.select-row {
  cursor: pointer;
}

.select-row p {
  margin: 0;
  margin-left: 7px;
}

.select2-selection__rendered .select-row {
  margin-bottom: 0;
}

.select2-container {
  background: red;
  padding-left: 26px;
  width: 136px;
  border-radius: 10px;
  background: #f2f2f2;
}

.select2 {
  background-image: url("../assets/images/icons/downArrow.svg");
  background-repeat: no-repeat;
  background-position: right 19px center;
}

.converter__item {
  padding-bottom: 46px;
  position: relative;
}
.converter__item2 {
  padding-bottom: 5px;
}
.containerr {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.footer {
  margin-top: auto;
  text-align: center;
  margin-bottom: 31px;
}

.footer__text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer__copy {
  font-size: 12px;
  max-width: 650px;
}

.phone__wrapper {
  max-width: 450px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

.phone__wrapper .select-row p {
  font-size: 14px;
  font-weight: 400;
}

.phone-input::-webkit-input-placeholder {
  color: #000;
  font-weight: 400;
}

.phone-input::-moz-placeholder {
  color: #000;
  font-weight: 400;
}

.phone-input:-ms-input-placeholder {
  color: #000;
  font-weight: 400;
}

.phone-input::-ms-input-placeholder {
  color: #000;
  font-weight: 400;
}

.phone-input::placeholder {
  color: #000;
  font-weight: 400;
}

.phone-input:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.phone-input:focus::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.phone-input:focus:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.phone-input:focus::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.phone-input:focus::placeholder {
  color: rgba(0, 0, 0, 0);
}

.phone {
  display: flex;
  align-items: center;
}

.phone-prefix {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.phone-input {
  border-right: none;
  border-left: 1px solid #fff;
  padding-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.phone-country {
  height: 21px;
  width: 21px;
}

.current-balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 24px;
}

.transfer-sent-balance {
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 34px;
  margin-top: 43px;
  margin-bottom: 38px;
}

.transfer-text {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.auth-wrapper {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

.verify-wrapper {
  width: 100%;
  max-width: 327px;
  margin-left: auto;
  margin-right: auto;
}

.verify-wrapper .sub-header {
  text-align: left;
}

.verify-wrapper .sub-header .sub-title {
  max-width: 100%;
}

.input {
  width: 43px;
  height: 48px;
  margin-right: 14px;
  outline: none;
  color: #1e1e1e;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  border: none;
  border-bottom: 2px solid #ffc38c;
  background-position: center;
  background-repeat: no-repeat;
}

.input:last-child {
  margin-right: 0;
}

.input:focus {
  background: rgba(0, 0, 0, 0);
}

.input:valid {
  background: rgba(0, 0, 0, 0);
  border-color: #1e1e1e;
}

.verify__inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.verify__inputs {
  margin-top: 60px;
}

.resend-code {
  color: #ff7a00;
  margin-top: 20px;
  font-size: 14px;
}

.resend-code-timer {
  margin-top: 20px;
  font-size: 14px;
}

.verify-button {
  display: flex;
  justify-content: center;
}
.verify-footer {
  margin-top: 8px;
  font-size: 14px;
}

.verify-footer__link {
  color: #ff7a00 !important;
  text-decoration: underline;
}

.verify-wrapper .button {
  margin-top: 60px;
}

.footer__text a {
  color: #1e1e1e;
}

.user-info-item {
  margin-top: 16px;
}

.text-label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.text-input {
  width: 100%;
  height: 48px;
  outline: none;
  border: none;
  background: #f2f2f2;
  -webkit-box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  border-radius: 10px;
  padding: 0 16px;
  font-family: "PlusJakartaSans", sans-serif;
}
.textCapitalize {
  text-transform: capitalize;
}

.text-input::-webkit-input-placeholder {
  color: #1e1e1e;
}

.text-input::-moz-placeholder {
  color: #1e1e1e;
}

.text-input:-ms-input-placeholder {
  color: #1e1e1e;
}

.text-input::-ms-input-placeholder {
  color: #1e1e1e;
}

.text-input::placeholder {
  color: #1e1e1e;
}

.text-select {
  width: 100%;
  height: 48px;
  background: #f2f2f2;
  -webkit-box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  border-radius: 10px;
  border: none;
  padding: 0 16px;
  font-family: "PlusJakartaSans", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  background-image: url("../assets/images/icons/downArrow.svg");
  background-repeat: no-repeat;
  background-position: right 19px center;
}

.lost-connection {
  min-height: 100vh;
}

.lost-connection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 368px);
  width: 100%;
}

.lost-connection-icon {
  height: 100px;
  width: 100px;
  margin-bottom: 24px;
}

.lost-connection-text {
  font-size: 20px;
}

.checkbox-wrapper {
  margin-top: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-wrapper label {
  margin-top: 0;
}

.checkbox {
  display: none;
}

.checkbox-label {
  width: 24px;
  height: 24px;
  border: 1px solid #1e1e1e;
  border-radius: 5px;
  margin-right: 16px;
  text-align: center;
  cursor: pointer;
}

.checkbox-link {
  padding-left: 0.35rem;
  color: #ff7a00 !important;
  text-decoration: underline;
}

.checkbox-link:hover {
  padding-left: 0.35rem;

  text-decoration: underline;
}

.checkbox-text-label {
  font-size: 14px;
}
/* 
.verify-wrapper--ver .button {
  margin-top: 220px;
} */

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sidebar {
  width: 400px;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100vh;
  min-height: 100%;
  padding-top: 50px;
  position: fixed;
}

.tab-content {
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  /* max-width: 800px; */
  margin-left: 0px;
  display: flex;
  justify-content: center;
}

.footer_logo {
  cursor: pointer;
}

.content_profile {
  position: relative;
}

.edit_user_currency {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  height: 300px;
  background-color: #fff;
}
/* Media query responsive */
.content_profile_page {
  height: 100vh;
}
.content_profile_page_background {
  /* opacity: 0.7; */
  background-color: rgba(0, 0, 0, 0.2);
}
.edit_user_currency_header {
  background-color: #f2f2f2;
  font-size: "14px";
  color: "#36393E";
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 26px;
}
.currency_description {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
}
.edit_user_currency_title {
  margin-bottom: 0;
}
.currency_description_container {
  padding: 30px;
}
.edit_user_currency_buttons {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  gap: 8px;
}
.edit_user_currency_cancel:hover {
  cursor: pointer;
}
.profile-item-inactive {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
/* home page responsive start*/
@media only screen and (max-width: 1250px) {
  .home-left .home-container {
    padding-inline: 24px !important;
  }

  .home-right .converter__wrapper {
    padding: 35px;
  }
}

@media only screen and (max-width: 1024px) {
  .home-left .header {
    padding: 16px 0;
  }

  .home-left .header .logo {
    font-size: 24px;
    line-height: 24px;
  }

  .home-left .header .logo .nixxeTittle {
    padding: 0;
  }

  .home-left .header .home-container {
    padding-inline: 20px !important;
  }

  .home-left .header .header_links_container {
    display: none;
  }

  .home-left .header .header_links_container.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    position: fixed;
    height: calc(100% - 56px);
    top: 56px;
    left: 0;
    width: 100%;
    z-index: 11;
    background-color: #fff;
    overflow: hidden;
    /* transform: rotate(0deg); */
  }

  .home-left .header .header_links {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
    flex: 1;
    width: 100%;
    gap: 8px;
  }

  .home-left .header .header_links li {
    border-bottom: 1px solid #f9fafb;
    padding: 12px 0;
    width: 100%;
  }

  .home-left .header .header_links li a {
    display: block;
    width: 100%;
    line-height: 24px;
    font-weight: 600;
  }

  .home-left .header-buttons {
    padding: 32px 24px;
    border-top: 1px solid #f9fafb;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .home-left .burger-icon {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
  }

  .buy-sell,
  .login-link {
    border: none;
    outline: none;
    border: 1px solid #ff7a00;
    font-family: PlusJakartaSans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px !important;
  }

  .buy-sell {
    display: flex !important;
    background-color: #ff7a00;
    color: #fff;
  }

  .login-link {
    color: #ff7a00 !important;
    background-color: #fff !important;
    top: 0 !important;
    width: 100% !important;
  }

  .home-left .burger-icon span {
    display: block;
    width: 18px;
    height: 2px;
    background-color: #344054;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    transition: all 0.3s ease;
  }

  .home-left .burger-icon span.first {
    top: 6px;
    transform: translateX(-50%);
  }
  .home-left .burger-icon span.second {
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .home-left .burger-icon span.third {
    bottom: 6px;
    transform: translateX(-50%);
  }

  .home-left .burger-icon.active span.first {
    top: 12px;
    transform: translateX(-50%) rotateZ(45deg);
  }
  .home-left .burger-icon.active span.second {
    display: none;
  }
  .home-left .burger-icon.active span.third {
    top: 12px;
    transform: translateX(-50%) rotateZ(-45deg);
  }

  /* buy sell modal style */
  .home-right {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 11;
    background-color: #fff;
    padding: 32px 20px;
  }

  .home-right > div {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .home-right.open {
    display: block;
  }

  .home-right .back-arrow {
    display: block;
  }

  .home-right .sub-header {
    padding-top: 32px;
  }

  .home-right .h2,
  .home-right .sub-title {
    text-align: start;
  }

  .home-right .sub-title {
    color: #333;
    margin-top: 10px;
    font-weight: 400;
  }

  .home-right .converter {
    flex: 1;
    margin-top: 40px;
    width: 100%;
  }

  .home-right .converter__wrapper {
    padding: 0;
    box-shadow: none;
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }

  .home-right .converter__wrapper .converter__item {
    width: 100%;
  }

  .home-right .converter__wrapper .button {
    margin-top: auto;
    max-width: 100%;
    border-radius: 8px;
    background: #ff7a00;
  }
}

/* home page responsive end */

@media only screen and (min-width: 992px) {
  .tab-content {
    width: 100%;
  }
  .logged-content {
    justify-content: flex-start;
    margin-left: 400px;
    padding-left: 100px;
    padding-top: 90px;
  }
}

@media only screen and (min-width: 769px) {
  .nonLogin-content {
    width: 90vw;
    display: flex;
    justify-content: center;
    height: 90vh;
  }
}

.sidebar__logo {
  color: #ff7a00 !important;
  font-size: 40px;
  font-weight: 600;
  padding-left: 30px;
  line-height: 100%;
}

.sidebar__menu {
  padding-top: 30px;
}

.sidebar__menu-link {
  display: block;
  padding: 17px 30px;
  border-bottom: 1px solid #f2f2f2;
  color: #1e1e1e;
}

.sidebar__menu-link.active {
  border-right: 4px solid #ff7a00;
}

.tab-content__header-value {
  font-size: 32px;
  font-weight: 600;
  color: #1e1e1e;
  margin-bottom: 24px;
}

.tab-content__header-value img {
  margin-right: 12px;
  display: inline-block;
  height: 10px;
  width: 17px;
  font-weight: 1000;
}

.tab-content__header-value span {
  font-size: 20px;
}

.tab-content__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.space-between {
  justify-content: space-between;
}

.tab-content__wrapper {
  max-width: 100%;
  height: 100%;
}

.tab-content__header__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tab-content__header__buttons .button {
  width: 110px;
  margin-left: 20px;
}

.walet__item {
  padding: 10px;
  border-bottom: 1px solid #f2f2f2;
}

.walet__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.walet__item-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.walet__icon {
  margin-right: 25px;
}

.walet__icon img {
  height: 32px;
  width: 32px;
}

.transactions__icon {
  margin-right: 28px;
}

.transaction-status {
  margin-bottom: 42px;
}

.transactions__icon img {
  height: 26px;
  width: 26px;
}

.walet__item-title {
  color: #262a3c;
  font-size: 14px;
}

.walet__item-text {
  font-size: 14px;
  color: #828282;
}

.walet__item-value {
  color: #262a3c;
  font-weight: 700;
}

.mobile-menu {
  display: none;
}

.footer-menu {
  display: none;
}

.converter__wrapper--buy {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  max-width: 450px;
  width: 100%;
}

.input-text {
  font-size: 14px;
  font-weight: 700;
  font-family: "PlusJakartaSans";
}

.converter__input-wrapper--start {
  font-weight: 400;
}

.text-input--link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
  color: #1e1e1e;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid transparent;
}

.top-up-iframe {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.converter__wrapper--buy.full-max-width {
  width: 100%;
}
.text-input--link.selected {
  border: 1px solid #ff7a00;
  box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  box-sizing: border-box;
}

.payment-method-desc {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0px;
  margin-left: 16px;
  display: inline-block;
  color: #ff7a00;
  margin-top: 6px;
}

.no-border {
  border-right: none;
}

.justify-end {
  justify-content: flex-end;
}

.approx.max {
  color: #000000;
  margin: 8px 0 0 20px;
}

.prompt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  background: #ffc618;
  padding: 20px;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 31px;
}

.prompt img {
  display: block;
  margin-right: 11px;
}

.walet__item {
  padding-left: 0;
  padding-right: 0;
}

.payment-request-modal {
  background: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
}

/* .modal-content {
  background: #fff;
  padding: 8vh;
  width: 450px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  max-width: 100%;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
} */
/* .modal-content {
  margin-top: 12rem;
} */

.modal-content-inside::-webkit-scrollbar {
  display: none;
}

.modal-content.active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 43px;
}

.close img {
  cursor: pointer;
}

.current-balanc {
  margin-top: 48px;
  margin-bottom: 28px;
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
}

.current-balanc span {
  font-weight: 700;
}

.payment-request-wrapper {
  text-align: center;
  max-width: 320px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 20px auto;
}

.important-notice2 {
  display: flex;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  background: #ffc618;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 4px;
  margin-top: 36px;
  margin-bottom: 30px;
  max-width: 320px;
  width: 100%;
  border-radius: 10px;
  margin: 36px auto;
  margin-top: 20px;
  padding: 20px;
}

.payment-request-value {
  font-family: "PlusJakartaSans";
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px;
}
.payment-request-amount {
  word-break: break-all;
}
.payment-request-crypto {
  margin-left: 5px;
}
.payment-request-value span {
  font-size: 20px;
}
.payment-request-title {
  font-family: "PlusJakartaSans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 16px;
  letter-spacing: 0px;
  max-width: 217px;
  margin: 5px auto 0 auto;
  word-break: break-word;
}

.transaction-alert-info {
  max-width: 320px;
  width: 100%;
  margin: 0 auto 20px;
  text-align: center;
}

.modal-buttons > :not(:last-child) {
  margin-bottom: 12px;
}
.modal-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .important-notice2 {
    width: auto;
  }
}

.walet__items--transactions .walet__item {
  cursor: pointer;
}

.walet__items-skeleton {
  padding: 0 20px;
  margin-top: 20px;
}

.profile-item__title .vector1 .tittle-1 .vvector1 {
  display: flex;
  align-items: center;
}

.profile-item {
  width: 100%;
  min-width: 400px;
  min-height: 62px;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 15px 21px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "PlusJakartaSans";
}
.profile-item1 {
  /* display: flex;
  text-align: center; */
  justify-content: space-between;
  /* cursor: pointer */
}
.profile-item__text {
  font-size: 15px;
  font-weight: 400;
}

.vector1 {
  float: left;
  width: 80%;
}
.tittle-1 {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.cardTittle {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
  font-weight: 700;
}

.vvector1 {
  float: right;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}
/* .sub-title1{
  margin-top: 29px;
  margin-bottom: 5px;
 
}
.text-inputemail{
  margin-bottom: 396px;
} */
.ei .profile-item--arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.converter__wrapper.phone__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0;
  padding: 50px 0;
}

@media only screen and (max-width: 1100px) {
  .sidebar {
    width: 300px;
  }

  .tab-content {
    margin-left: 0;
  }
  .logged-content {
    margin-left: 300px;
    padding-top: 90px;
  }
}

@media only screen and (min-width: 992px) {
  .term-header {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .header {
    padding: 15px 0;
  }
  .logged-content {
    padding-left: 20px;
  }

  .tab-content {
    width: 100%;
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .sidebar {
    min-width: 300px;
  }
}

.tittlePosition {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 870px) {
  .tab-content {
    padding-left: 30px;
  }
  .tab-content__header-value {
    font-size: 25px;
  }
}
@media only screen and (max-width: 510px) {
  .converter__wrapper {
    padding: 30px 20px 40px 20px;
  }

  .select2-container {
    padding-left: 10px;
  }

  .converter__item {
    padding-bottom: 20px;
  }
  .converter__item2 {
    padding-bottom: 5px;
  }
  .sub-header {
    padding-top: 20px;
  }
  .converter__wrapper--buy {
    padding: 0;
  }

  .walet__items--transactions .walet__item-value {
    font-size: 14px;
  }

  .walet__item-title {
    font-size: 12px;
  }

  .walet__item-text {
    font-size: 12px;
  }

  .modal-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
  }

  .tab-content__header-value {
    font-size: 30px;
  }
}

@media only screen and (max-width: 375px) {
  .withdraw {
    height: 667px;
  }

  .input {
    width: 35px;
    margin-right: 0;
  }

  .verify__inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .tab-content {
    padding-left: 24px;
  }
  .titleOfModal {
    max-width: 327px;
  }
}
@media only screen and (max-height: 667px) {
  .btniPhoneSE {
    position: absolute;
    bottom: 0;
    margin-top: 20px;
  }
}

.inputCode {
  border: none;
  /* border-bottom: 1px solid #ffc38c; */
  width: 43px;
  height: 48px;
  font-size: 36px;
  line-height: 24px;
  text-align: center;
  border-color: #ffc38c;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}

.container img {
  position: relative;
  left: 25px;
  bottom: 5px;
}

.dotImageStyle img {
  position: relative;
  left: 25px;
  bottom: 0px;
}

.inputCode:focus {
  outline: none;
  text-align: center;
  height: 48px;
  font-size: 36px;
  line-height: 24px;
  /* border-bottom: 1px solid #000000; */
  border-color: #000000;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}

.containerOfModal {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.content-center-modal {
  margin: 0 auto;
  padding: 20px 0;
  max-width: 450px;
  text-align: center;
}

.modal-header {
  border: none;
}

.titleOfModal {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-align: left;
  line-height: 40px;
  max-width: 600px;
}
@media screen and (max-width: 420px) {
  .titleOfModal {
    max-width: 327px;
  }
  .converter__wrapper--buy {
    margin: 20px 0;
  }
}
.inputStyle {
  border: none;
  background-color: #f2f2f2;
  box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  border-radius: 10px;
  padding: 18px;
  height: 48px;
  width: 100%;
  color: #1e1e1e;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: "PlusJakartaSans";
  outline: none;
}

.styleofinputsmodal {
  display: flex;
  background-color: #f2f2f2;
  border-radius: 10px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  border: none;
  padding: 0 16px;
}
.modalBorder {
  border: 2px solid #ff7a00;
}
.afterTypeInput {
  text-align: center;
  height: 48px;
  font-size: 36px;
  line-height: 24px;
  /* border-bottom: 1px solid #000000; */
  margin-left: 10px;
  color: inherit;
  text-decoration: none;
  border-color: #000000;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}

.searchImg {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 24px;
}
.searchImg img {
  padding-left: 18px;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: auto !important;
    padding: 0;
    top: 44px !important;
    left: 40px !important;
    right: 40px !important;
  }
  .tab-content {
    padding-left: 24px;
    padding-right: 24px;
  }
  .afterTypeInput {
    font-size: 18px;
    box-shadow: none;
  }
  .inputCode {
    font-size: 18px !important;
  }
}
.Toastify {
  max-width: 295px;
  position: absolute;
  right: 5%;
  top: 5%;
  border-radius: 10px;
  z-index: 9999;
}

.Toastify__toast {
  border-radius: 12px !important;
  font-size: 1rem;
  margin-top: 20px;
}
.Toastify__toast:first-child {
  margin-top: 0px;
}

.Toastify__close-button {
  display: none;
}

/* .Toastify__toast-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
} */
.Toastify__toast-icon {
  margin-right: 1.5rem !important;
}

.Toastify__toast--error {
  background: #f64b3c !important;
  color: #ffffff !important;
}

.Toastify__toast--success {
  background: #ff7a00 !important;
  color: #ffffff !important;
}
.containerOfDeposit {
  margin-top: 29px;
  max-width: 327px;
}
.containerOfDeposit2 {
  margin-top: 55px;
  text-align: center;
}
.containerOfDeposit3 {
  text-align: center;
  width: 327px;
}
.HeaderTitle {
  margin-top: 45px;
  margin-bottom: 36px;
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
}

.HeaderTitleDeposit {
  margin-top: 75px;
  margin-bottom: 33px;
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0px;
}
.HeaderTitleDeposit2 {
  margin-top: 42px;
  margin-bottom: 33px;
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0px;
}
.labelStyleInput3 {
  margin-bottom: 23px;
}

.faadback__input-wrapper {
  background: #f2f2f2;
  -webkit-box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  box-shadow: 0px 5px 15px rgba(209, 209, 209, 0.03);
  border-radius: 4px;
  padding: 12px 16px;
  height: 48px;
  max-width: 450px;
  width: 100%;
  margin-bottom: 16px;
}
.taxtarea_input {
  height: 141px;
}
.faadback_input {
  height: 100%;
  width: 100%;
  font-family: "PlusJakartaSans";
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  resize: none;
}
.faadback_paragraph {
  font-family: "PlusJakartaSans-Medium";
  font-size: 14px;
  line-height: 20px;
  color: #1e1e1e;
  margin-bottom: 0px;
  text-align: left;
  letter-spacing: 0px;
  font-weight: 500;
}
.faadback_paragraph:last-of-type {
  margin-bottom: 10px;
}
.faadback_icon {
  width: 11.25px;
  height: 20px;
  margin-bottom: 25px;
  cursor: pointer;
}
.faadback_label {
  margin-bottom: 5px;
  font-family: "PlusJakartaSans";
  height: 18px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
  color: #1e1e1e;
  align-items: left;
}
.faadback__item {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
}
.faadback_button {
  position: absolute;
  bottom: 35px;
}
.button_valid {
  background: #ffc38c;
}
.termBody {
  max-width: 600px;
  width: 100%;
}
.prevLogo {
  margin-top: 59px;
  margin-left: 30px;
  margin-bottom: 30px;
  margin-right: 333.75px;
}
.topTitle {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 26px;
  letter-spacing: 0px;
  color: #1e1e1e;

  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
.styleOfInput {
  width: 327px;
  background: #f2f2f2;
  border-radius: 10px !important;
  margin-bottom: 16px;
}
.inputText {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1e1e1e;
  width: 200px;
}
.arrowEndInput {
  padding: 17px;
  padding-left: 0;
  color: #1e1e1e;
}

.labelStyleInput2 {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
}
.labelStyleInput {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-left: 14px;
  margin-bottom: 12px;
}
.labelStyleInput4 {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-left: 14px;
  margin-bottom: 12px;
  margin-top: 20px;
}
.inputStyle2 {
  padding: 16px;
  height: 56px;
  padding-right: 0;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 327px;
}
.inputStyle2:focus {
  border: none;
  outline: none;
}
.buttonStyle {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  border: none;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 16px;
  width: 56px;
  margin-right: 11px;
  justify-content: center;
}
.submitButton {
  width: 259px;
  height: 49px;
  text-align: center;
  border-radius: 27px;
  background: #ffc38c;
  border: none;
  color: #ffffff;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.submitButton2 {
  width: 259px;
  height: 49px;
  text-align: center;
  border-radius: 27px;
  background: #ff7a00;
  border: none;
  color: #ffffff;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

@media (max-height: 720px) {
  .payment-reqyest-value {
    font-size: 20px;
  }
  .transaction-alert-info {
    font-size: 12px;
  }
}
@media (max-height: 620px) {
  .current-balance {
    margin-top: 0;
  }
}

.product-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgValid {
  background: #ff7a00;
}
.inputSumValue {
  background: none;
  outline: none;
  border: none;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
}
.term-content {
  margin-left: 24px;
  margin-right: 24px;
  top: 210px;
}

.policy-content {
  margin-left: 4px;
  margin-right: 4px;
  max-width: 100%;
  text-align: justify;
}

.withdraw {
  margin-left: 4px;
  margin-right: 4px;
  padding: 0px;
}
.withdraw-header {
  width: 375px;

  margin: 0px;
}
.fieldContent {
  margin-left: 4px;
  margin-right: 24px;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.input-fields input {
  width: 327px;
  height: 48px;
  background: #f2f2f2;
  border-radius: 10px;
  border: none;
  padding-left: 20px;
  margin-bottom: 12px;
  margin-top: 5px;
}

.AuthoriseBtn button {
  width: 259px;
  height: 49px;
  background: #ff7a00;
  border: none;
  color: #ffffff;
  height: 49px;
  width: 259px;
  left: 0px;
  top: 0px;
  margin-left: 32px;
  margin-right: 52px;
  border-radius: 27px;
  margin-top: 51px;
}
.AuthoriseBtn1 button {
  width: 259px;
  height: 49px;
  background: #ff7a00;
  border-radius: 27px;
  border: none;
  color: #ffffff;
  height: 49px;
  width: 259px;
  left: 0px;
  top: 0px;
  margin-left: 32px;
  margin-right: 52px;
  border-radius: 27px;
  margin-top: 344px;
  position: absolute;
  width: 327px;
  display: flex;
  justify-content: center;
  bottom: 35px;
}

.important-notice {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  background: #ffc618;
  border-radius: 10px;
  width: 90%;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 36px;
  margin-bottom: 30px;
  height: 100px;
  width: 327px;
  left: 0px;
  top: 0px;
  border-radius: 10px;
}

.text-notice {
  margin-left: 17px;
  margin-right: 3px;
  justify-content: center;
  align-items: center;
  font-family: "PlusJakartaSans";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.text-notice p {
  height: 60px;
  width: 250px;
  left: 61px;
  top: 20px;
}
.text-notixe-content span {
  font-weight: 700;
}
.withdraw-header::placeholder {
  font-style: "Regular";
  font-family: "PlusJakartaSans";
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 40px;
}

.input-fields {
  font-family: "PlusJakartaSans";
  font-weight: 700;
}
.paragraph1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.para1 {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0px;
  color: #1e1e1e;
}
.switch {
  visibility: hidden;
  display: flex;
  width: 5px;
  height: 10px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}
.label1 {
  display: flex;
  width: 63px;
  height: 32px;
  background-color: #9c9c9c;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  background: #cccccc;
  border-radius: 50px;
  position: relative;
}

.label1::after {
  content: "";
  width: 27px;
  height: 28px;
  top: 2px;
  left: 2px;
  background-color: #e8f5f7;
  position: absolute;
  border-radius: 70px;
  transition: 0.5s;
}
input:checked + .label1 {
  background-color: #ff7a00;
}
input:checked + .label1::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
input[type="checkbox"] {
  width: 0;
  height: 0;
  visibility: hidden;
}
.tittle1 {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-top: 25px;
  margin-bottom: 24px;
  letter-spacing: 0px;
  color: #1e1e1e;
}
.dot-flashing {
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fff;
  color: #fff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 3.75rem auto;
}
button .dot-flashing {
  margin: 0 0;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -1.56rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fff;
  color: #fff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 1.56rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fff;
  color: #fff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #fff;
  }
  50%,
  100% {
    background-color: #9b9999;
  }
}
.labelStyleInput3 {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 34px;
  line-height: 24px;
  text-align: center;
}

.tickimagedeposit {
  position: relative;
  top: 0px;
  right: 43px;
}
.headertitle {
  /* margin-left: 24px; */
  font-size: 32px;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
}
.arrowIcon {
  /* margin-top: 59px; */
  margin-left: 30px;
  margin-bottom: 25px;
}
.slider.round:before {
  border-radius: 50%;
}
.backArrow {
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.close-nav {
  display: flex;
  margin-bottom: 70px;
}

.close-nav.close {
  margin-top: 10px;
  flex-direction: column;
}

.close-nav.close .modal-header {
  margin-bottom: 27px;
  margin-right: 0;
}

.close-nav.progerss-bar .modal-header {
  margin-right: 0;
  margin-bottom: 0;
}

.modal-header {
  padding: 0;
  margin-right: 24px;
}
.backBtn {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: self-start;
}
.decimalText {
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 10px;
}
.settings_card {
  display: flex;
  position: relative;
  border-bottom: 1px solid #eaf0ff;
  height: 60px;
  align-items: center;
}
.settings_text {
  padding-left: 23px;
}
.settings_items {
  max-width: 450px;
  width: 100%;
  padding-bottom: 100px;
}

.settingsArrow {
  position: absolute;
  right: 0%;
}
.settingsCardIcon {
  height: 24px;
}
.settings_button {
  position: fixed;
  bottom: 90px;
  display: flex;
  justify-content: center;
  width: 327px;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
}
.inputArrowStyleImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px;
}
.flag_img {
  height: 20px;
}
.security_title {
  width: 327px;
  height: 40px;
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0px;
  color: #1e1e1e;
  margin-bottom: 12px;
}
.security_paragraph {
  width: 327px;
  height: 61px;
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
}
.containerOfWithdraw {
  margin-top: 32px;
  max-width: 327px;
}
.inputBankTransfer {
  height: 63px;
  border-radius: 10px;
  background: #f2f2f2;
  margin-bottom: 8px;
  max-width: 327px;
  margin-left: 4px;
  padding-top: 13px;
  padding-left: 16px;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #262a3c;
}
.transferPage {
  padding-bottom: 1px;
}
.transferPage2 {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #262a3c;
}
.inputStyle span {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #ff7a00;
  width: 90px;
  height: 24px;
  padding-left: 50px;
}
.balanceParagraph {
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #262a3c;
  text-align: right;
  width: 310px;
}
.balanceParagraph span {
  font-weight: 700;
}

.but1 {
  background-color: #ff7a00;
  border-radius: 27px;
  border: none;
  text-align: center;
  width: 100%;
  max-width: 171px;
  height: 45px;
  color: whitesmoke;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}
.but2 {
  background-color: #f2f2f2;
  border-radius: 27px;
  border: none;
  text-align: center;
  max-width: 171px;
  width: 100%;
  height: 45px;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #1e1e1e;
}
.closeButoon {
  margin: 64px 20px 47px auto;
}
.modal_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;
}

.modal_title span {
  margin-top: 42px;
}

.scanIDtext {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  padding-top: 1.25rem;
  letter-spacing: 0px;
  color: #262a3c;
  margin-bottom: 1.68rem;
}
/* .scanIDbtn {
  margin: 222px auto !important;
  margin-bottom: 0 !important;
} */
.button_group {
  border-radius: 27px;
  margin: 0 auto;
  max-width: 335px;
  height: 45px;
  display: flex;
  background-color: orange;
  background: rgba(242, 242, 242, 1);
  margin-bottom: 30px;
}
.finishedButtonDesigne {
  background-color: #ff7a00;
  border-radius: 27px;
  border: none;
  text-align: center;
  width: 171px;
  height: 36px;
  color: whitesmoke;
  font-family: "PlusJakartaSans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}
.scrollHeader {
  position: fixed;
  top: 0;
  width: 100%;
}
.titleWidth {
  max-width: 600px;
}
.hideScrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.modal-backdrop {
  pointer-events: none;
}

.modal-backdrop.show {
  pointer-events: all;
}
.offcanvas.offcanvas-end {
  border: none;
  visibility: visible;
  z-index: 1051;
  width: 450px;
}

.offcanvas-header {
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 0;
}

.offcanvas-body {
  padding: 0;
}

.offcanvas-header .btn-close {
  margin-bottom: 47px;
  align-self: flex-end;
  padding: 0;
  margin: 0 0 50px;
}

.btn-close:focus {
  box-shadow: none;
}

.btn-close {
  opacity: 1;
}

.offcanvas-wrapper {
  height: 100%;
  text-align: "center";
  padding: 60px;
}

.offcanvas-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0;
  overflow: auto;
}
.sticky {
  padding-top: 60px;
}
.checkiconinmoda {
  width: 24px;
  height: 24px;
  background: #ff7a00;
  border-radius: 12px;
  padding: 6px;
}
.balanceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spinnerPosition {
  display: flex;
}

.button_title {
  padding-top: 15px;
  font-weight: 400;
}
.button_position_scanId {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scrollContent {
  padding-top: 80px;
}
.stickyContent {
  position: absolute;
  height: 100%;
  width: 100%;
}

.parent {
  text-align: center;
}

.borderNone {
  border: none;
}
a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.LoginParagraph {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
}
.paragraphOfLink {
  text-decoration: underline;
}
.logoOfCurrency {
  margin-top: 15px;
  margin-bottom: 30px;
}
.cryptosDiv {
  width: 100%;
}

.skeletonBtnBorder {
  border: 1px solid rgb(85, 80, 80);
  opacity: 0.2;
}
@keyframes loading-skeleton {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 0.4;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(50) contrast(15%) brightness(0.9);
  border: none !important;
  border-radius: 5px;
  margin-left: 10px;
}
.loading-skeleton h1,
.loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: rgb(222, 217, 217);
  border-color: #eee;
}
.loading-skeleton h2 {
  filter: grayscale(50) contrast(15%) brightness(0.9);
  border: 10px solid red;
  height: 20px;
  background-color: rgb(0, 0, 0);
  width: 400px;
}

.skeleton {
  opacity: 0.7;
  border-radius: 5px;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
}
.skeletonDivSpecial {
  background-color: grey;
  margin-top: 20px;
  margin-bottom: 42px;
  width: 95%;
  border-radius: 7px;
  height: 20px;
  opacity: 0.5;
}

.balanaceSkeleton {
  height: 10px;
  background-color: rgb(181, 173, 173);
  width: 100px;
  padding-top: 0px;
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 5px;
  opacity: 0.5;
}
.amountSkeleton {
  height: 10px;
  background-color: rgb(181, 173, 173);
  width: 200px;
  padding-top: 0px;
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 5px;
  opacity: 0.5;
}

.mobile-menu_skeleton {
  width: 200px;
  background: grey;
  height: 20px;
  margin-top: 4px;
  margin-left: 20px;
  border-radius: 5px;
}
.image_menu_skeleton {
  background-color: rgb(181, 173, 173);
  width: 32px;
  border-radius: 7px;
  height: 32px;
  border-radius: 8px;
}

.notifications {
  max-width: 450px;
  width: 100%;
}

.feedback {
  margin-bottom: 46px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 45%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #111111;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
.settings_tittle {
  max-width: 600px;
}

@media only screen and (max-width: 350px) {
  .styleOfInput {
    width: 280px;
  }
  .containerOfWithdraw {
    width: 100%;
  }
  .buttonStyle {
    padding: 12px;
  }
  .inputStyle2 {
    width: 280px;
  }

  .balanceParagraph {
    width: auto;
  }
  .inputStyle span {
    padding-left: 0px;
  }
  .important-notice {
    width: auto;
  }
  .text-notice p {
    width: auto;
  }
  .input-fields input {
    width: 255px;
  }
  .containerOfDeposit3 div {
    width: auto;
  }
  .settings_tittle {
    width: 280px;
  }
}
.input_disabled {
  cursor: not-allowed;
}
.resend-code {
  border: none;
  background-color: white;
  text-decoration: underline;
  padding-left: 0;
}
.nixxeTittle {
  border: none;
  background-color: white;
  color: #ff7a00;
}
.waletitem__border {
  border-bottom: 0;
}
.editIcon:hover {
  cursor: pointer;
}
.profile_logout_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.profile_currency_container {
  justify-content: space-between;
}
.kycEmail {
  color: #3a5fa5;
  text-decoration: underline;
  font-family: "PlusJakartaSans";
  font-size: 14px;
}
@media only screen and (max-width: 365px) {
  .searchImg {
    margin-top: 55px;
  }
  .converter__input-wrapper--start {
    width: 132px;
  }
}
@media only screen and (max-width: 380px) {
  .input-text {
    font-size: 12px;
  }
  .input_disabled {
    font-size: 12px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  .input[typr="number"],
  textarea {
    font-size: 16px;
  }
}

.sell.spinnerPosition {
  text-align: start;
  margin-left: 8px;
  margin-top: 2px;
}

.sell.spinnerPosition .spinner-border {
  width: 16px;
  height: 16px;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profile-item__title {
  text-transform: capitalize;
}
.profile_item__title {
  text-transform: none;
}
.faadback__input {
  text-transform: capitalize;
}

.upperCaseText {
  text-transform: uppercase;
}
.fontResetHome {
  color: grey;
  opacity: 0.8;
}
.transaction-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-all;
}

.transaction-success-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 33px;
  text-align: center;
  width: 100%;
}

.transaction-success-subtext,
.transaction-success-text {
  width: 100%;
  font-weight: 400;
  line-height: 100%;
}

.transaction-success-text {
  margin-bottom: 35px;
  font-size: 34px;
}

.transaction-success-subtext {
  font-size: 16px;
}

.transaction-success-button {
  align-self: center;
  margin-top: 52px;
}

.body_position {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.center {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .empty-data {
    position: static;
    margin-top: 60px;
  }
  .h2 {
    font-size: 22px;
  }

  .h3 {
    font-size: 18px;
  }

  .logo {
    font-size: 45px;
  }

  .login-link {
    font-size: 16px;
  }

  .sub-header {
    padding-top: 30px;
  }

  .sidebar {
    display: none;
  }

  .auth-wrapper {
    max-width: none;
    padding: 0 24px;
  }

  .notifications {
    max-width: unset;
  }

  .walet__items {
    margin-bottom: 35px;
  }

  .body_position {
    height: 100%;
    max-width: none;
    justify-content: space-between;
  }

  .transaction-alert-info {
    font-size: 12px;
  }

  .settings_items {
    max-width: none;
  }

  .tab-content {
    min-height: 100vh;
  }

  .content-center-modal {
    max-width: none;
    padding: 24px;
  }

  .converter__wrapper.phone__wrapper {
    padding-top: 20px;
    padding-bottom: 35px;
  }

  .transactions__icon {
    margin-right: 12px;
  }

  .faadback__input-wrapper {
    max-width: none;
  }

  .tab-content__header__buttons {
    display: none;
  }

  .tab-content__header {
    padding-bottom: 30px;
  }

  .tab-content {
    margin-left: 0px;
    margin: 0 auto;
    padding-top: 40px;
    padding-left: 30px;
    padding-bottom: 35px;
  }

  .mobile-menu {
    display: block;
    background: #f2f2f2;
    border-radius: 10px;
    padding: 0 14px;
    margin-bottom: 36px;
  }

  .close-nav {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .offcanvas-wrapper {
    padding: 20px;
    padding-bottom: 35px;
  }

  .modal-header {
    margin-right: 0;
    margin-bottom: 45px;
  }

  .offcanvas.offcanvas-end.show {
    width: 100%;
  }

  .phone__wrapper {
    width: 100%;
    max-width: unset;
  }

  .edit-contact-wrapper {
    min-height: 100vh;
  }

  .mobile-menu__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc;
  }

  .left-border {
    border-left: 1px solid #1e1e1e;
  }

  .walet__icon {
    margin-right: 25px;
  }

  .mobile-menu__item-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mobile-menu__item-icon {
    margin-right: 19px;
  }

  .mobile-menu__item:last-of-type {
    border-bottom: 0;
  }

  .footer-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 37px;
    z-index: 999;
  }

  /* .content {
    padding-bottom: 100px;
  } */

  .footer-menu__item {
    position: relative;
  }

  .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #ff7a00;
    position: absolute;
    bottom: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .request-value {
    position: absolute;
    top: 0;
    right: 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ff7a00;
    color: #fff;
    font-size: 8px;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .settings_tittle {
    width: 100%;
  }
  .tittle_position {
    display: flex;
    justify-content: center;
  }

  .converter__wrapper--buy {
    max-width: unset;
  }

  .converter_body {
    width: 100%;
  }

  .dropdown {
    position: fixed;
    width: auto;
    max-height: 80vh;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 34px 40px 13px 40px;
    background-color: #ffffff;
    border-radius: 28px 28px 0px 0px;
    z-index: 1040;
    transform: translateY(100%);
  }

  .dropdown-list {
    margin-top: 88px;
  }

  .dropdown :last-child {
    border-bottom: 0;
  }

  .dropdown.active {
    transform: translateY(0);
  }

  .dropdown-item {
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px solid #f2f2f2;
  }

  .dropdown-item img {
    width: 40px;
    height: 40px;
  }

  .dropdown :first-child:hover {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .dropdown .close {
    position: absolute;
    align-self: flex-end;
    padding: 0;
    height: 26px;
    width: 26px;
    background-size: cover;
  }

  .dropdown.currencies {
    max-width: unset;
  }

  .profile-items {
    width: 100%;
  }
}
.strong {
  font-weight: bold;
}
.space-right {
  margin-right: 4px;
}
.contact_us_btn {
  color: #ff7a00;
  background-color: #f1f4f6;
  border: 1px solid #ff7a00;
  border-radius: 4px;
  margin-top: 16px;
  width: 160px;
  padding: 6px 0;
}
.fill:hover {
  box-shadow: inset 0 0 0 2em #d3580b;
}
.reversedFill:hover {
  box-shadow: inset 0 0 0 2em #d3580b;
  color: white;
  background-color: orange;
}
